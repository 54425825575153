<template>
    <div class="fitnessplan">

        <!-- TABS -->
        <div class="tabs tabs--fullwidth">
            <div class="tabs__content">
                <v-btn to="/fitnessplan" text v-ripple="false">Als Nächstes</v-btn>
                <v-btn to="/fitnessplan/calendar" text v-ripple="false">Kalender</v-btn>
                <v-btn class="is-active" text v-ripple="false">Challenges</v-btn>
                <!-- <v-btn text v-ripple="false">Archiv</v-btn>
                <v-btn text v-ripple="false">Statistik</v-btn> -->
            </div>
        </div>

        <!-- 3 Beispiele für Fitnessplan-Zustände -->
            <div v-for="challenge in getActiveChallenges" v-bind:key="challenge.id"
                 class="fitnessplan__item fitnessplan__item--active">
                <div class="fitnessplan__item__content">
                    <div class="fitnessplan__item__badge"><span>Aktiv</span>・ {{ challenge.badge }}</div>
                    <h3 class="fitnessplan__item__title">{{ challenge.title }}</h3>
                    <img :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url"
                         class="fitnessplan__item__image" alt="">
                </div>
                <div class="fitnessplan__item__footer">
<!--                    <div class="fitnessplan__item__footer__button">-->
<!--                        <img src="@/assets/ic_16_fitnessplan_edit.svg" alt="">-->
<!--                        Bearbeiten-->
<!--                    </div>-->
                    <div @click="confirmDeleteChallenge(challenge)" class="fitnessplan__item__footer__button">
                        <img src="@/assets/ic_16_fitnessplan_cancel.svg" alt="">
                        Beenden
                    </div>
                </div>
            </div>

            <div v-for="challenge in getCompletedChallenges" v-bind:key="challenge.uniqueChallengeId"
                 class="fitnessplan__item">
                <div class="fitnessplan__item__content"  @click="openChallengeSuccess(challenge)">
                    <div class="fitnessplan__item__badge"><span>Bereits absolviert</span>・{{
                            challenge.badge
                        }}
                    </div>
                    <h3 class="fitnessplan__item__title">{{ challenge.title }}</h3>
                    <img :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url"
                         class="fitnessplan__item__image" alt="">
                </div>
                <div v-if="isNotActiveChallenge(challenge)" class="fitnessplan__item__footer">
                    <div @click="openFitnessplanDetailModal(challenge)" class="fitnessplan__item__footer__button">
                        <img src="@/assets/ic_24_play.svg" alt="">
                        Erneut starten
                    </div>
                </div>
            </div>

            <div v-for="challenge in selectableChallenges" v-bind:key="challenge.id"
                 class="fitnessplan__item" @click="openFitnessplanDetailModal(challenge)">
                <div class="fitnessplan__item__content">
                    <div class="fitnessplan__item__badge">
                        <span>Noch nicht absolviert</span>・{{ challenge.badge }}
                    </div>
                    <h3 class="fitnessplan__item__title">{{ challenge.title }}</h3>
                    <img :src="'https://app.sportlerplus.com/scaled/800/' + challenge.image_url"
                         class="fitnessplan__item__image" alt="">
                </div>
            </div>
        <ConfirmDeleteChallengeModal @deleteFitnessPlanChallenge="deleteFitnessPlanChallenge"
                                     :fitnessPlanEvent="currentChallenge"
                                     name="confirmDeleteChallengeModal"></ConfirmDeleteChallengeModal>


        <FitnessplanDetailModal :challenge="currentChallenge"></FitnessplanDetailModal>
    </div>
</template>

<script>

import ConfirmDeleteChallengeModal from "@/components/ConfirmDeleteChallengeModal";
import FitnessplanDetailModal from '@/components/FitnessplanDetailModal';
import {analytics} from "@/firebase";

export default {
    name: 'FitnessplanChallenges',
    components: {ConfirmDeleteChallengeModal,FitnessplanDetailModal},
    data() {
        return {
            currentChallenge: {},
            fitnessPlan: null,
            challenges: [],
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        activeChallenges() {
            if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                return this.fitnessPlan.startedChallenges.filter(el => el.inProgress);
            }
            return [];
        },
        getActiveChallenges() {
            if (!this.challenges) { return []; }
            let userChallenges = [...this.activeChallenges];
            userChallenges.forEach(el => {
                Object.assign(el, this.challenges.find(c => parseInt(c.id) === parseInt(el.id)))
            });
            return userChallenges;
        },
        selectableChallenges() {
            if (!this.challenges) { return []; }
            return this.challenges.filter(el => {
                let c = this.activeChallenges.find(ac => parseInt(ac.id) === parseInt(el.id)) || this.completedChallenges.find(ac => parseInt(ac.id) === parseInt(el.id));
                return (c == null);
            });
        },
        completedChallenges() {
            if (this.fitnessPlan && this.fitnessPlan.startedChallenges) {
                return this.fitnessPlan.startedChallenges.filter(el => !el.inProgress);
            }
            return [];
        },
        getCompletedChallenges() {
            if (!this.challenges) { return []; }
            let userChallenges = [...this.completedChallenges];
            userChallenges.forEach(el => {
                Object.assign(el, this.challenges.find(c => parseInt(c.id) === parseInt(el.id)))
            });
            return userChallenges;
        },
    },
    methods: {
        openFitnessplanDetailModal(challenge) {
            this.currentChallenge = challenge;
            this.$modal.show('fitnessplanDetail');
        },
        closeFitnessplanDetailModal() {
            this.$modal.hide('fitnessplanDetail');
        },
        openChallengeSuccess(challenge) {
            const uniqueChallengeId = challenge.uniqueChallengeId;
            this.$router.push("/fitnessplan/challenge/success?unique_challenge_id=" + uniqueChallengeId);
        },
        async init() {
            await this.$store.dispatch("fetchChallenges").then(() => {
                this.challenges = this.$store.getters.getChallenges;
            });
            this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                }
            });

            if (this.$route.query.challengeId) {
                Object.keys(this.challenges).forEach(key => {
                    const item = this.challenges[key]
                    if (typeof this.$route.query.challengeId === 'string' && parseInt(item.id) === parseInt(this.$route.query.challengeId)) {
                        this.currentChallenge = item
                    }
                })
                this.$modal.show('fitnessplanDetail');
            }
        },

        confirmDeleteChallenge(activeChallenge) {
            this.currentChallenge = activeChallenge;
            this.$modal.show("confirmDeleteChallengeModal");
        },
        deleteFitnessPlanChallenge() {
            this.fitnessPlan.items = this.fitnessPlan.items.filter(fpi => !fpi.uniqueChallengeId || fpi.uniqueChallengeId !== this.currentChallenge.uniqueChallengeId);
            this.fitnessPlan.startedChallenges = this.fitnessPlan.startedChallenges.filter(sc => sc.uniqueChallengeId !== this.currentChallenge.uniqueChallengeId);
            this.fitnessPlan.startedActivities = this.fitnessPlan.startedActivities.filter(sa => !sa._uniqueChallengeId || !(sa.activityState == "started" && sa._uniqueChallengeId === this.currentChallenge.uniqueChallengeId));
            this.$store.commit('clearMotivationTextAsDisplayed', this.currentChallenge.uniqueChallengeId);
            analytics.logEvent('cancel_challenge', { challenge_id: this.currentChallenge.id });
            this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
            this.filteredCalendar = [];
            this.calendar = [];
            this.unrollCalendar();
            this.$modal.hide("confirmDeleteChallengeModal");
        },
        unrollCalendar() {
            this.$store.dispatch('unrollCalendar').then( (res) => {
                this.calendar = res;
                this.filterCalendar();
            });
        },
        filterCalendar() {
            if (this.hasDateFilter) {
                let filterDay = this.moment(this.$route.query.date);
                this.filteredCalendar = this.calendar.filter(c => {
                    return this.moment(filterDay).isSame(c.startDate, 'day');
                });
            }
            else {
                let thresDate = new Date();
                thresDate.setDate(thresDate.getDate() - 3);
                this.filteredCalendar = this.calendar.filter(c => c.startDate > thresDate).slice(0, 32);
            }
        },
        isNotActiveChallenge(challenge) {
            return !this.activeChallenges.some(c => challenge.id === c.id);
        },
    },
}
</script>

<style scoped>

</style>